import FileAudio from '$lib/icons/fontawesome/file-audio.svelte';
import FileImage from '$lib/icons/fontawesome/file-image.svelte';
import FilePen from '$lib/icons/fontawesome/file-pen.svelte';
import FileVideo from '$lib/icons/fontawesome/file-video.svelte';
// import * as Sentry from '@sentry/svelte';
import dayjs from 'dayjs';
import { Contact, TeamUserRole, type PortalUser, type TMessage, type Team } from 'fixee-server';
import {
	Portal,
	PortalStatus,
	PortalWidgetType,
	type PortalMessageAttrLive,
	type SetMyStatusData
} from 'fixee-server/lib/services/portal/portal.shared';
import {
	SubscriptionStatus,
	type Subscription,
	type SubscriptionFeatures
} from 'fixee-server/lib/services/subscription/subscription.shared';
import { UserRole, type User } from 'fixee-server/lib/services/user/user.shared';
import orderBy from 'lodash/orderBy';
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
import useFixeeClient from '../../api/fixee-client';

export const DIV_ID_SCROLL_BOTTOM_PORTAL = 'scroll-bottom-div-portal';
export const DIV_ID_SCROLL_BOTTOM_ASSISTANT = 'scroll-bottom-div-assistant';
export const UNLIMITED_SUBSCRIPTION_SMS_LIMIT = 90000000;

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export const updateMyPortalStatus = async (
	data: Partial<SetMyStatusData> & Pick<SetMyStatusData, 'portalId'>,
	token?: string
) => {
	const params = new URL(window.location.href).searchParams;

	return await useFixeeClient()
		.service('portal')
		.setMyStatus(
			{
				...data
			} as any,
			{
				query: {
					portalUserToken: params.get('t') || token
				}
			}
		);
};

export function dataURLtoBlob(dataurl: any) {
	const arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], { type: mime });
}

export const downloadBlob = (blob: Blob, filename: string) => {
	const url = window.URL.createObjectURL(blob);
	downloadUrl(url, filename);
};

export const downloadUrl = (url: string, filename?: string) => {
	const a = document.createElement('a');
	a.style.display = 'none';
	a.target = '_blank';
	a.href = url;
	// The filename you want
	// This only works for files on the same origin as the current page.
	if (filename) a.download = filename;
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);
};

export const fileToBlob = (file: File) => {
	return new Blob([file], { type: file.type });
};

export const getDateFileNamePng = () => {
	return `${dayjs().format('YYYY_DD_MM__HH[h]_mm[m]_ss[s]')}.png`;
};

export const getDateFileNameOgg = () => {
	return `${dayjs().format('YYYY_DD_MM__HH[h]_mm[m]_ss[s]')}.mp3`;
};

// function to add a 0 before number is < 10
export const addZero = (i: number) => (i < 10 ? '0' + i : i);

export const getDetailedValuesFromSeconds = (seconds: number) => {
	// This is useful for when the seconds is a small float or else the text value can be a little weird after
	if (seconds < 0.1) seconds = 0;

	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const seconds2 = Math.floor(seconds % 60);

	return {
		hours,
		minutes,
		seconds: seconds2
	};
};

export const getDurationTextFromSeconds = (seconds = 0) => {
	const detailedDuration = getDetailedValuesFromSeconds(seconds);

	const hoursText = detailedDuration.hours > 0 ? `${addZero(detailedDuration.hours)}h ` : '';
	const minutesText =
		detailedDuration.minutes > 0 ? `${addZero(detailedDuration.minutes)}m ` : '';
	const secondsText = `${addZero(detailedDuration.seconds)}s`;

	return `${hoursText}${minutesText}${secondsText}`.trim();
};

export const getAudioDurationTextFromSeconds = (seconds: number) => {
	const detailedDuration = getDetailedValuesFromSeconds(seconds);

	const hoursText = detailedDuration.hours > 0 ? `${addZero(detailedDuration.hours)}:` : '';
	const minutesText = `${addZero(detailedDuration.minutes)}:`;
	const secondsText = `${addZero(detailedDuration.seconds)}`;

	return `${hoursText}${minutesText}${secondsText}`.trim();
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const getTeamName = (team: Team | undefined, _: any) => {
	return team?.individualId ? _('d.home.myTeam') : team?.name;
};

export const getFullname = (firstName?: string | null, lastName?: string | null) => {
	if (!firstName && !lastName) return undefined;
	if (firstName && !lastName) return firstName;
	if (!firstName && lastName) return lastName;

	return `${firstName} ${lastName}`;
};

export const getStringWithDots = (str: string, limit = 0) => {
	if (limit && str.length > limit) return `${str.slice(0, limit)}...`;

	return str;
};

export const availableTeamUserRoles = [
	TeamUserRole.Hotliner,
	TeamUserRole.Admin,
	TeamUserRole.AdminAndHotliner
];

export const availableUserRoles = [
	UserRole.Default,
	UserRole.Manager,
	UserRole.Admin,
	UserRole.SuperAdmin
];

export const loadMyTeams = async () => {
	let teams = (
		await useFixeeClient()
			.service('team')
			.find({
				query: {
					$limit: 50,
					$sort: { createdAt: 1 },
					$loadPublicLogo: true,
					$loadSubscription: true
				}
			})
	).data;

	// We make sure that the first team is the one with the individualId
	teams = orderBy(teams, ['individualId']);

	return teams;
};

export const HIDE_PORTAL_ANNOTATION = 'hide-portal-annotation';
export const triggerHidePortalAnnotation = () => {
	window.dispatchEvent(new CustomEvent(HIDE_PORTAL_ANNOTATION, {}));
};

// This is useful for svelte-portal, when we want the div to follow it's parent during the scroll (it's not really its parent because it's added to the DOM)
export const SCROLL_EVENT = 'scroll-event';
export const triggerScrollEvent = () => {
	window.dispatchEvent(new CustomEvent(SCROLL_EVENT, {}));
};

export const getNavigatorDevices = async () => {
	const devices = await navigator.mediaDevices.enumerateDevices();

	// deviceId can be empty when you haven't accepted the permissions yet, so we remove them
	const videoInputs = devices.filter(
		(device) => device.kind === 'videoinput' && device.deviceId !== ''
	);
	const audioInputs = devices.filter(
		(device) => device.kind === 'audioinput' && device.deviceId !== ''
	);
	const audioOutputs = devices.filter(
		(device) => device.kind === 'audiooutput' && device.deviceId !== ''
	);

	return {
		devices,
		videoInputs,
		audioInputs,
		audioOutputs
	};
};

export const openCustomerPortal = async (teamId: string) => {
	try {
		const result = await useFixeeClient().service('pricing').createCustomerPortalSession({
			teamId
		});

		window.open(result.url, '_blank');
	} catch (error) {
		sentryCaptureException(error);
		console.error('Error openCustomerPortal', error);
	}
};

export const sentryCaptureException = async (error: any) => {
	// Sentry.captureException(error);
};

export const getTotalDurationAndLives = (
	liveMessages: TMessage[] | undefined,
	type: TMessage['type']
) => {
	const messages = (liveMessages || []).filter((m) => m.type === type);

	let totalEffectiveDuration = 0;
	const effectiveDurations: number[] = [];

	for (const message of messages) {
		const attributes = message.attributes as PortalMessageAttrLive;

		if (attributes.effectiveDuration) {
			totalEffectiveDuration += attributes.effectiveDuration;
			effectiveDurations.push(attributes.effectiveDuration);
		}
	}

	return {
		totalEffectiveDuration,
		effectiveDurations
	};
};

export const getPortalUserName = (
	portalUser: PortalUser,
	not_user_anymore: any | undefined = undefined
) => {
	const firstName = portalUser.contact?.firstName || portalUser.teamUser?.firstName;
	const lastName = portalUser.contact?.lastName || portalUser.teamUser?.lastName;

	return getPersonDisplayValue(
		get(_)(`portal.roles.${portalUser.role}`),
		firstName,
		lastName,
		portalUser.contact?.phone,
		portalUser.contact?.email
	);
};

export const getContactDisplayValue = (
	contact: Contact,
	format: 'default' | 'long' = 'default'
) => {
	let displayValue = getPersonDisplayValue(
		get(_)(`portal.roles.1`),
		contact?.firstName,
		contact?.lastName,
		contact?.phone,
		contact?.email
	);

	const otherValues = [];
	if (contact?.phone) otherValues.push(contact.phone);
	if (contact?.email) otherValues.push(contact.email);

	if ((contact?.firstName || contact?.lastName) && format === 'long' && otherValues.length > 0) {
		displayValue = `${displayValue} (${otherValues.join(', ')})`;
	} else if (format === 'long' && otherValues.length === 2) {
		displayValue = `${otherValues.join(', ')}`;
	}

	return displayValue;
};

export const getPersonDisplayValue = (
	fallbackStr: string,
	firstName?: string | null,
	lastName?: string | null,
	phone?: string | null,
	email?: string | null
) => {
	const fullname = getFullname(firstName, lastName);

	if (!fullname) {
		const wayOfContacting = phone || email;
		if (wayOfContacting) return wayOfContacting;

		return fallbackStr;
	}

	return fullname;
};

export const getIconFromDocumentType = (docType: string) => {
	if (docType === 'audio') return FileAudio;
	if (docType === 'file') return File;
	if (docType === 'image') return FileImage;
	if (docType === 'text') return FilePen;
	if (docType === 'video') return FileVideo;

	return undefined;
};

export const setProcedureIdToPortal = async (
	fixeeClient: any,
	portalId: string,
	procedureId: string
) => {
	try {
		await fixeeClient.service('portal').patch(portalId, {
			procedureId
		});
	} catch (error) {
		console.error('setProcedureIdToPortal error', error);
	}
};

export const getTeamFirstString = (user: User | undefined, team: Team) => {
	return (team.individualId ? user?.firstName : team.name) || '';
};
export const getTeamSecondString = (user: User | undefined, team: Team) => {
	return team.individualId ? user?.lastName : undefined;
};

export const getSubscriptionBadgeColorFromStatus = (subscriptionStatus: SubscriptionStatus) => {
	let color: any = 'green';
	if (subscriptionStatus === SubscriptionStatus.Paused) color = 'yellow';
	if (subscriptionStatus === SubscriptionStatus.Inactive) color = 'red';

	return color;
};

// IMPORTANT : Soon use the function from the fixee-server, it's the same but it has to be shared
export const hasSubscriptionFeature = (
	subscription: Subscription | undefined,
	team: Team | undefined, // for now, we pass the team here because we also want to check the credits, remove this when we completly remove the credits
	feature: SubscriptionFeatures | 'live' | 'sms'
) => {
	// If the team has no subscription but more than 0 credits, they have access to all the features
	if (!subscription && team && team.credits > 0) return true;

	if (!subscription?.active) return false;

	const excludedFeatures = ['feature_live_generate_composition'];
	if (subscription?.isUnlimited && !excludedFeatures.includes(feature)) {
		return true;
	}

	if (feature === 'live') {
		return subscription.nbLiveMinutesUsed < subscription.nbLiveMinutesTotal;
	}

	if (feature === 'sms') {
		return subscription.nbSmsSent < subscription.nbSmsTotal;
	}

	return subscription.features.includes(feature);
};

export function formatLiveMinutesToHours(nbLiveMinutesTotal: number) {
	return (nbLiveMinutesTotal / 60).toFixed(2).replace(/[.,]00$/, '');
}

export function getSubscriptionUsage(subscription?: Subscription) {
	// Color is red because there is not subscription
	if (!subscription)
		return {
			liveMinutesPct: 0,
			smsPct: 0,
			smsColor: 'red',
			liveMinutesColor: 'red',
			endsAtColor: 'red',
			subscriptionColor: 'red',
			active: false
		};
	const liveMinutesPct = Math.round(
		(subscription.nbLiveMinutesUsed / subscription.nbLiveMinutesTotal) * 100
	);
	const smsPct = Math.round((subscription.nbSmsSent / subscription.nbSmsTotal) * 100);

	let smsColor: any = 'blue';
	let liveMinutesColor: any = 'blue';
	let endsAtColor: any = 'blue';
	let subscriptionColor: any = 'blue';

	// Get days diff between subscription.endsAt and now
	const endsAt = dayjs(subscription.endsAt);
	const daysDiff = endsAt.diff(dayjs(), 'day');

	if (daysDiff < 61) {
		endsAtColor = 'yellow';
		subscriptionColor = 'yellow';
	}
	if (daysDiff < 31) {
		endsAtColor = 'red';
		subscriptionColor = 'red';
	}

	if (smsPct > 75) {
		smsColor = 'yellow';
		subscriptionColor = 'yellow';
	}
	if (liveMinutesPct > 75) {
		liveMinutesColor = 'yellow';
		subscriptionColor = 'yellow';
	}
	if (smsPct > 85) {
		smsColor = 'red';
		subscriptionColor = 'red';
	}
	if (liveMinutesPct > 85) {
		liveMinutesColor = 'red';
		subscriptionColor = 'red';
	}

	return {
		liveMinutesPct,
		smsPct,
		smsColor,
		liveMinutesColor,
		endsAtColor,
		subscriptionColor,
		active: subscription.active
	};
}

export const getDefaultTablePortalsFilters = () => {
	return {
		search: '',
		dateStart: undefined,
		dateEnd: undefined,
		types: [],
		teamUserIds: [],
		tagIds: [],
		statuses: [],
		contactIds: []
		// Maybe at some point we will not show the "Assistant" status
		// statuses: [PortalStatus.Opened, PortalStatus.Closed, PortalStatus.AwaitingExpert]
	};
};

export const getShowCredits = (subscription: Subscription | undefined, team: Team | undefined) => {
	return !subscription && team && team.credits > 0;
};

export const getButtonGrayClass = (disabled = false) => {
	return `!bg-slate-100 ${disabled ? '' : 'hover:!bg-slate-200'}`;
};

export const pickWidget = (type: PortalWidgetType) => {
	const customEvent = new CustomEvent('widget-picked', {
		detail: {
			widget: {
				type
			}
		}
	});
	window.dispatchEvent(customEvent);
};

export const getPortalTypeStyle = (portal: Portal, _: any) => {
	let color: any = 'blue';
	if (portal.type === 2) color = 'purple';
	if (portal.type === 3) color = 'yellow';
	const text = portal.assistant?.name ? portal.assistant.name : _(`portal.type.${portal.type}`);

	return {
		color,
		text
	};
};

// Function to handle clicks and timing
export function handleRapidClicks(
	fnc: () => void,
	settings: { nbClick: number; duration: number }
) {
	let clickCount = 0;
	let startTime = 0;

	return function () {
		const now = Date.now(); // Current time in milliseconds

		if (startTime === 0) {
			// Start the timer on the first click
			startTime = now;
		} else if (now - startTime > settings.duration) {
			// Reset if more than 2 seconds have passed
			clickCount = 0;
			startTime = now;
		}

		clickCount++; // Increment the click counter

		// Check if there have been more than X clicks within X seconds
		if (clickCount > settings.nbClick) {
			fnc(); // Trigger the desired function
			// Reset after action is taken
			clickCount = 0;
			startTime = 0;
		}
	};
}

export const getStatusLabelColor = (
	status: PortalStatus
): 'green' | 'red' | 'yellow' | 'red' | 'slate' | undefined => {
	if ((status as number) === -1) return 'slate';
	if (status === PortalStatus.Opened) return 'green';
	if (status === PortalStatus.Closed) return 'red';
	if (status === PortalStatus.Assistant) return 'yellow';
	if (status === PortalStatus.AwaitingExpert) return 'red';
};

export function findMyPortalUser(portal: Portal, currentUser?: User, token?: string) {
	const portalUser = portal.portalUsers.find((pu: PortalUser) => {
		// If there is a token, we only check the token
		return (
			(pu.teamUserId && currentUser?.teamIds.includes(pu.teamUserId) && !token) ||
			pu.token === token
		);
	});

	return portalUser || (portal as any).portalUserViewer;
}

export function formatWithoutYear(date: Date | string, formatString: string) {
	const dateYear = dayjs(date).year();
	const currentYear = dayjs().year();

	if (dateYear === currentYear) {
		// Usually the format is 'DD/MM/YYYY HH:mm', if we user other format, we should add another replace to cover it
		formatString = formatString.replace('/YYYY', '');
	}

	return dayjs(date).format(formatString);
}
